.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

body {
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 14px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2.4em;
  line-height: 140%;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2em;
  line-height: 36px;
  font-weight: 700;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.6em;
  line-height: 30px;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3em;
  line-height: 1.3em;
  font-weight: 500;
}

p {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 170%;
}

a {
  text-decoration: underline;
}

label {
  display: block;
  font-weight: 700;
}

.rich-data-content {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  margin-top: -56px;
  margin-bottom: -56px;
  padding-top: 64px;
  padding-right: 64px;
  padding-left: 64px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fff;
}

.section {
  position: relative;
  padding-bottom: 60px;
}

.section.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.body {
  background-color: #d8d8d8;
}

.rich-data-nav {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  background-color: #f8f8f8;
}

.map {
  position: fixed;
  left: 0%;
  top: 56px;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 99999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #fff;
}

.nav.narrow {
  padding-right: 48px;
  padding-left: 48px;
}

.section-link {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  margin-top: -140px;
}

.panel-toggle {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  margin-top: 12px;
  padding: 10px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.05)), color-stop(16%, transparent));
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.05), transparent 16%);
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: width 200ms cubic-bezier(.23, 1, .32, 1), padding 200ms cubic-bezier(.23, 1, .32, 1);
  transition: width 200ms cubic-bezier(.23, 1, .32, 1), padding 200ms cubic-bezier(.23, 1, .32, 1);
  cursor: pointer;
}

.panel-toggle.cc-active {
  color: #39ad84;
}

.rich-data {
  position: relative;
  left: 0px;
  top: 56px;
  right: auto;
  z-index: 4;
  display: none;
  width: 1184px;
  max-width: 98%;
}

.rich-data.webflow {
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.location-highlight__value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
}

.map-location {
  position: absolute;
  top: 16px;
  bottom: auto;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 4px;
  background-color: hsla(0, 0%, 100%, 0.63);
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 0 1px 6px -2px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.map-location:hover {
  background-color: #fff;
}

.location-tag__loading-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  margin-right: -4px;
  margin-left: 2px;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  font-size: 0.8em;
}

.location-tag__loading-icon.hidden {
  display: none;
}

.location-tag__type {
  position: absolute;
  top: -7px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 12px;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 0.6em;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.location-tag {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  max-width: 180px;
  min-width: 80px;
  margin: 4px;
  padding-top: 3px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #707070;
  background-image: none;
  box-shadow: 0 2px 7px -4px #000;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.location-tag:hover {
  border-color: #ccc;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color: #fff;
}

.location-tag.active {
  background-color: #333;
}

.location-highlight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 160px;
  margin-top: 4px;
  padding-top: 8px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.location-highlight.last {
  border-right-style: none;
}

.location-highlight__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  color: #666;
  font-size: 0.6em;
  line-height: 100%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.map-location__info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.map-location__tags {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.rich-data-nav__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  padding-top: 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.category-header__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.rich-data-toggles {
  position: -webkit-sticky;
  position: sticky;
  top: 68px;
  z-index: 2;
  width: 44px;
  height: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.svg-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
}

.svg-icon.small {
  width: 16px;
}

.svg-icon.large {
  width: 32px;
  height: 32px;
}

.svg-icon.svg-icon--no-size {
  width: auto;
  height: auto;
}

.svg-icon.is--walkthrough-arrow-left {
  width: 16px;
  margin-right: 0.5em;
}

.svg-icon.is--walkthrough-arrow-right {
  width: 16px;
  margin-left: 1em;
}

.svg-icon.is--cluster-icon {
  display: none;
  width: 12px;
  height: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.svg-icon.is--cluster-icon.is--shown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.svg-icon.is--small {
  width: 12px;
  height: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-clear {
  margin: 0px;
  padding: 0px;
  line-height: 100%;
}

.location__breadcrumbs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -3px;
  margin-right: -3px;
  margin-left: -3px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 24px;
  margin: 3px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: #707070;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 0.85em;
  text-transform: capitalize;
  cursor: pointer;
}

.breadcrumb:hover {
  background-color: #333;
  color: #fff;
}

.location-facility__category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 0.85em;
  font-weight: 500;
}

.location__facilities_title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 140%;
  text-transform: none;
}

.location-facility__item_name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-left: 4px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.location__facilities {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.location-facility__item_value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.location-facility__description {
  margin-top: 6px;
  padding-top: 6px;
  padding-right: 12px;
  border-top: 1px dashed #ccc;
  color: #999;
  font-size: 0.8em;
  line-height: 130%;
}

.location-facility__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  padding-top: 6px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-top: 1px solid #ccc;
  color: #666;
  font-size: 0.8em;
}

.location-facility__card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 8px 10px 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 2px;
  background-color: #f0f0f0;
  line-height: 100%;
}

.location-facility {
  width: 25%;
  padding: 6px;
}

.location-facility__icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.location-facility__value {
  margin-top: 4px;
  margin-left: 6px;
}

.location-facility__list_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 28px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 4fr 1fr 0.75fr;
  grid-template-columns: 4fr 1fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #707070;
  line-height: 170%;
  text-decoration: none;
}

.location-facility__list_item:hover {
  border-bottom-color: transparent;
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
}

.location-facility__list_item.last {
  border-bottom-style: none;
}

.location-facility__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.location__title {
  margin-bottom: 24px;
  text-transform: capitalize;
}

.rich-data-bumper {
  position: relative;
  top: 56px;
  width: 180px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #f8f8f8;
}

.rich-data-nav__toggle {
  position: absolute;
  left: auto;
  top: 72px;
  right: -22px;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  background-color: #707070;
  box-shadow: 0 0 20px -14px #000;
  color: #fff;
  cursor: pointer;
}

.rich-data-nav__toggle:hover {
  border-color: #f0f0f0;
  background-color: #333;
}

.rich-data-nav__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 2px solid transparent;
  color: #707070;
  font-size: 0.9em;
  text-decoration: none;
  white-space: nowrap;
}

.rich-data-nav__item:hover {
  border-right-color: #707070;
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
}

.rich-data-nav__item:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.rich-data-nav__item.w--current {
  border-right-color: #39ad84;
  background-color: #fff;
  color: #333;
  white-space: nowrap;
}

.rich-data-nav__item.w--current:hover {
  background-color: #ebebeb;
}

.rich-data-nav__item-icon {
  width: 44px;
  height: 44px;
  padding: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.rich-data-nav__item-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  color: #333;
  text-align: right;
}

.nav__shadow {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -16px;
  width: 100%;
  height: 16px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.08)), color-stop(34%, rgba(0, 0, 0, 0.02)), to(transparent));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.02) 34%, transparent);
}

.location__facilities_sources {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-right: 6px;
  margin-left: 6px;
  padding-bottom: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.label {
  margin-right: 2px;
  margin-left: 2px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #333;
  font-size: 0.85em;
}

.data-source {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 28px;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-left: 2px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  text-decoration: underline;
  cursor: pointer;
}

.data-source:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.category-header__text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-header__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-top: 4px;
  padding-right: 12px;
  padding-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
  padding-bottom: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px solid #c7c7c7;
}

.sub-category-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px solid #c7c7c7;
}

.sub-category-header.first {
  padding-top: 0px;
  border-top-style: none;
}

.indicator__title_wrapper {
  position: relative;
}

.sub-category-header__title {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}

.sub-category-header__key-metrics {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid #c7c7c7;
}

.sub-category-header__key-metrics_title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
  color: #999;
  font-size: 0.9em;
  line-height: 140%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.h3__line-v {
  position: absolute;
  left: -2px;
  top: auto;
  right: 0%;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 3px;
}

.sub-category-header__description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.link-block:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.key-metric {
  width: 25%;
  padding: 6px;
}

.key-metric__card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  padding: 10px 10px 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 2px;
  background-color: #f0f0f0;
  line-height: 100%;
}

.key-metric__value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2px;
  font-size: 1.8em;
  line-height: 1em;
}

.key-metric__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #666;
  font-size: 0.8em;
  line-height: 1.3em;
}

.key-metric__description {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px dashed #ccc;
  color: #999;
  font-size: 0.8em;
  line-height: 130%;
}

.dropdown-menu__icon {
  width: 24px;
  height: 24px;
}

.bar-chart__label_position {
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
}

.dropdown-menu__label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 6px;
  color: #999;
  font-size: 0.7em;
  line-height: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.profile-indicator__chart_description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 450px;
  margin-bottom: 6px;
  padding-top: 1em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 2px;
  color: #999;
  font-size: 0.8em;
}

.profile-indicator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  padding-bottom: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px solid #c7c7c7;
}

.profile-indicator.last {
  border-bottom-style: none;
}

.profile-indicator__chart_source {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  padding: 0.8em 0px 0.8em 8px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 2px;
  font-size: 0.85em;
}

.dropdown-menu__content {
  position: absolute;
  top: -4px;
  z-index: 10;
  display: none;
  width: 100%;
  max-height: 200px;
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
  color: #666;
}

.dropdown-menu__content.scroll-element {
  overflow: auto;
}

.dropdown-menu__content.position-top {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: none;
}

.dropdown-menu__content.position-top.scroll-element.show {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.dropdown-menu__content.dropdown-menu__content--top {
  top: auto;
  bottom: -4px;
}

.profile-indicator__chart {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.bar-chart__labels {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-left: 100px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #999;
}

.bar-chart__label {
  position: relative;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.05);
}

.profile-indicator__options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 24px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-menu__trigger {
  position: relative;
  z-index: 9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 36px;
  padding: 6px 6px 6px 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.dropdown-menu__trigger:hover {
  background-color: #ebebeb;
}

.dropdown-menu__trigger.narrow {
  height: 35px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.dropdown-menu__trigger.disabled {
  cursor: default;
}

.filter__dropdown_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 6px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.filter__dropdown_wrap.disabled {
  opacity: 0.4;
}

.dropdown-menu {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 0.9em;
}

.chart__footer_label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #333;
}

.bar-chart__underline {
  height: 1px;
  margin-top: 16px;
  margin-left: 100px;
  background-color: #e6e6e6;
}

.dropdown__list_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 32px;
  padding: 4px 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.dropdown__list_item:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #000;
}

.dropdown__list_item:active {
  background-color: rgba(0, 0, 0, 0.24);
}

.dropdown__list_item.selected {
  color: #000;
  font-weight: 500;
}

.dropdown-menu__selected-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1.2;
}

.profile-indicator__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  padding-bottom: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.profile-indicator__header.demo {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-indicator__filters {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bar-chart__rows {
  position: relative;
  padding-bottom: 6px;
}

.bar-chart {
  position: relative;
  width: 100%;
  font-size: 1em;
}

.profile-indicator__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -8px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.profile-indicator__chart_body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  padding-right: 44px;
}

.indicator__chart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.indicator__chart.full {
  width: 100%;
  margin-bottom: 16px;
}

.bar-chart__x-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 32px;
  padding-left: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #999;
  font-size: 0.7em;
  text-transform: uppercase;
}

.profile-indicator__chart_footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px dashed #e6e6e6;
}

.hover-menu__content_item--no-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 12px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.85em;
}

.hover-menu__content_list-item--last {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #333;
  font-size: 0.85em;
  text-decoration: none;
  cursor: pointer;
}

.hover-menu__content_list-item--last:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.icon--small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 16px;
  height: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hover-menu__content_list--last {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 32px;
  margin-top: 4px;
  margin-bottom: -4px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px solid #e6e6e6;
}

.hover-menu__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.hover-menu__icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.hover-menu__content_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 32px;
  padding: 4px 12px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-size: 0.85em;
  text-decoration: none;
}

.hover-menu__content_item:hover {
  background-color: #f0f0f0;
}

.hover-menu__content {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 999;
  display: none;
  overflow: hidden;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
}

.hover-menu__content.webflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.hover-menu__content_list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 32px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.hover-menu {
  position: relative;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 2px;
}

.hover-menu__content_list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #e6e6e6;
  color: #333;
  font-size: 0.85em;
  text-decoration: none;
}

.hover-menu__content_list-item:hover {
  background-color: #f0f0f0;
}

.hover-menu__content_list-item.active {
  margin-top: -1px;
  margin-bottom: -1px;
  border-style: solid;
  border-width: 1px;
  border-color: #666;
  background-color: #f0f0f0;
}

.hover-menu__content_list-item.active:hover {
  color: #000;
}

.hover-menu__content_list-item.last {
  border-right-color: transparent;
}

.hover-menu__content_list-item.last.active {
  border-right-color: #666;
}

.hover-menu__content_wrapper {
  min-width: 200px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
}

.content__item_title {
  margin-left: 6px;
}

.sticky-header__current-location {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-top: 16px;
  margin-left: -16px;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 100px;
  background-color: #666;
  background-image: none;
  box-shadow: 0 0 20px -14px #000;
  color: #fff;
  font-size: 1em;
  text-decoration: none;
  text-transform: capitalize;
}

.bar-chart__row_tooltip {
  position: absolute;
  left: 50%;
  top: -29px;
  right: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bar-chart__tooltip_alt-value {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 6px;
  padding-left: 6px;
  border-left: 1px solid #f0f0f0;
}

.bar-chart__row_bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  height: 100%;
  min-width: 1px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: none;
}

.bar-chart__row_bar:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.bar-chart__row_bar._1 {
  width: 28%;
}

.bar-chart__tooltip_value {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 6px;
  padding-left: 6px;
  border-left: 1px solid #f0f0f0;
  color: #000;
  font-weight: 700;
}

.bar-chart__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  margin-bottom: 6px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: hsla(0, 0%, 94.5%, 0);
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #999;
  cursor: pointer;
}

.bar-chart__row:hover {
  color: #000;
}

.bar-chart__row_tooltip-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 8px -4px #000;
  font-size: 0.8em;
  white-space: nowrap;
}

.bar-chart__row_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100px;
  max-width: 100px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 0.8em;
  line-height: 120%;
}

.bar-chart__row-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: none;
}

.bar-chart__row-wrapper:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(0, 0, 0, 0.04)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04));
}

.bar-chart__row_tooltip-notch {
  position: absolute;
  bottom: -4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 8px;
  height: 8px;
  border-style: none solid solid none;
  border-width: 1px;
  border-color: #ccc;
  background-color: #fff;
  box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.86);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bar-chart__main {
  position: relative;
}

.bar-chart__row_value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8em;
}

.bar-chart__label_value {
  font-size: 0.85em;
}

.search__dropdown_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.search__list-item_location-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-left: 12px;
  color: rgba(0, 0, 0, 0.32);
  font-size: 0.85em;
}

.nav__search_button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 37px;
  margin-top: -1px;
  margin-right: -1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 2px;
  background-color: #707070;
  color: #fff;
}

.search__list-item_location-type {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  opacity: 0.47;
  font-size: 0.85em;
}

.nav__content_title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.search__list-item_location-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.search__dropdown_list-item {
  display: -ms-grid;
  display: grid;
  height: 32px;
  max-width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1.5fr 1fr 0.75fr;
  grid-template-columns: 1.5fr 1fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 2px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.search__dropdown_list-item:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  color: #000;
}

.search__dropdown_list-item.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  color: #000;
}

.nav__profile-logo {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  border-radius: 4px;
}

.search__dropdown_results-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  font-size: 0.85em;
  text-transform: uppercase;
}

.nav__search_dropdown {
  position: relative;
  top: 20px;
  display: none;
  max-height: 450px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.nav__search_dropdown.webflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav__menu-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  margin-left: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.nav__menu-trigger:hover {
  border-radius: 0px;
  color: #39ad84;
}

.nav__search_input {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.nav__search_input:active {
  background-color: #f0f0f0;
}

.location__search_input {
  height: 100%;
  margin-bottom: 0px;
  padding: 0px 8px 0px 12px;
  border: 1px none #000;
  background-color: transparent;
  font-size: 1.2em;
}

.location__search_input:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.nav__content_menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search__input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #333;
}

.location__search_loading {
  position: absolute;
  left: auto;
  right: 60px;
  display: none;
  width: 36px;
  height: 36px;
  padding: 8px;
  opacity: 0.4;
}

.location__search_loading.hidden {
  display: none;
}

.search__dropdown_plate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-height: 350px;
  padding: 6px 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 3px;
  background-color: #fff;
}

.nav__content {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 2fr 2.25fr 1fr;
  grid-template-columns: 1fr 2fr 2.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav__content.narrow {
  width: 960px;
  margin-right: auto;
  margin-left: auto;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-right: 12px;
  padding-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  color: #707070;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;
}

.nav__link:hover {
  color: #333;
}

.nav__link:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.nav__link.tutorial__open {
  color: #fff;
}

.nav__link.tutorial__open:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.search__dropdown_list {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav__content_search {
  position: relative;
  z-index: 99999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 37px;
  max-width: 600px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
  border-radius: 2px;
}

.nav__search-deselect {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 9999;
  display: none;
  width: 100vw;
  height: 100vh;
}

.more-info {
  position: relative;
  z-index: 1;
  width: 20px;
  border-radius: 100%;
}

.more-info:hover {
  color: #303030;
}

.point-mapper {
  position: fixed;
  left: 0%;
  top: 56px;
  right: auto;
  bottom: 0%;
  z-index: 2;
  display: none;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
  font-family: Roboto, sans-serif;
}

.point-mapper.webflow {
  left: 0px;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.point-mapper-toggles {
  position: absolute;
  left: 100%;
  top: 12px;
  width: 44px;
  height: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.panel-toggles {
  position: fixed;
  left: 0%;
  top: 68px;
  right: auto;
  bottom: auto;
  z-index: 1;
}

.data-mapper {
  position: fixed;
  left: 0%;
  top: 56px;
  right: auto;
  bottom: 0%;
  z-index: 3;
  display: none;
  min-width: 300px;
  background-color: #fff;
}

.data-mapper.webflow {
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.point-mapper-content {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  padding: 22px 14px 14px 18px;
}

.point-mapper-content.narrow-scroll {
  z-index: 1;
  overflow: scroll;
}

.data-mapper-toggles {
  position: absolute;
  left: 100%;
  top: 12px;
  width: 44px;
  height: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.point-mapper__h1_content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.point-mapper__h2_line-h {
  position: absolute;
  left: -16px;
  z-index: 1;
  width: 8px;
  height: 1px;
  margin-top: -1px;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.point-mapper__h2_loading {
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  opacity: 0.4;
}

.point-mapper__h2_loading.hidden {
  display: none;
}

.point-mapper__h2_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-top: 6px;
  margin-bottom: 6px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.point-mapper__h2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 38px;
  margin-bottom: 4px;
  padding-right: 36px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #f0f0f0, #f0f0f0);
  color: #666;
  font-size: 0.85em;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.point-mapper__h2:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #f0f0f0, #f0f0f0);
  color: #000;
}

.point-mapper__h2.last {
  margin-bottom: 12px;
}

.point-mapper__h2.theme-1.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dee7ff), to(#dee7ff)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #dee7ff, #dee7ff), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#eadbfa), to(#eadbfa)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #eadbfa, #eadbfa), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#eadbfa), to(#eadbfa)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #eadbfa, #eadbfa), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-3:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-3.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd1e7), to(#ffd1e7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffd1e7, #ffd1e7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-3.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffd1e7), to(#ffd1e7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffd1e7, #ffd1e7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-4.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd7d7), to(#ffd7d7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffd7d7, #ffd7d7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-4.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffd7d7), to(#ffd7d7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffd7d7, #ffd7d7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-5.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffe3fc), to(#ffe3fc)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffe3fc, #ffe3fc), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-5.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffe3fc), to(#ffe3fc)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffe3fc, #ffe3fc), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-6.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffeadd), to(#ffeadd)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffeadd, #ffeadd), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-6.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffeadd), to(#ffeadd)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffeadd, #ffeadd), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-7.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff8e4), to(#fff8e4)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #fff8e4, #fff8e4), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-7.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#fff8e4), to(#fff8e4)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #fff8e4, #fff8e4), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-8.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cef3b2), to(#cef3b2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #cef3b2, #cef3b2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-8.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#cef3b2), to(#cef3b2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #cef3b2, #cef3b2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-9.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cdfaf2), to(#cdfaf2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #cdfaf2, #cdfaf2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-9.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#cdfaf2), to(#cdfaf2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #cdfaf2, #cdfaf2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-10.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#d6eaeb), to(#d6eaeb)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #d6eaeb, #d6eaeb), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-10.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#d6eaeb), to(#d6eaeb)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #d6eaeb, #d6eaeb), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  max-width: 293px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.point-data__h2_line-v {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 1px;
  margin-bottom: 32px;
  margin-left: 12px;
  background-color: rgba(0, 0, 0, 0.11);
}

.point-mapper__h2_wrapper {
  position: relative;
  padding-left: 23px;
}

.point-data__h1_name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-right: 0.5em;
  margin-bottom: 0px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #333;
  font-size: 0.9em;
  line-height: 1.2;
  font-weight: 500;
}

.point-mapper__h2_load-complete {
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.4;
}

.point-mapper__h2_load-complete.hidden {
  display: none;
}

.point-mapper__h2_name {
  line-height: 1.2em;
}

.point-mapper__h1_checkbox {
  position: absolute;
  left: auto;
  top: 0%;
  right: 36px;
  bottom: auto;
  z-index: 1;
  display: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.point-mapper__h1_checkbox.is--shown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.point-mapper__h1_trigger {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 36px;
  margin-bottom: 8px;
  padding-right: 36px;
  padding-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-radius: 2px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #f0f0f0, #f0f0f0);
  text-decoration: none;
  cursor: pointer;
}

.point-mapper__h1_trigger:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #f0f0f0, #f0f0f0);
  color: #000;
}

.point-mapper__h1_trigger.theme-1 {
  color: #3a70ff;
}

.point-mapper__h1_trigger.theme-1.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dee7ff), to(#dee7ff)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #dee7ff, #dee7ff), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-1.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#dee7ff), to(#dee7ff)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #dee7ff, #dee7ff), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-2 {
  color: #993aff;
}

.point-mapper__h1_trigger.theme-2.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#eadbfa), to(#eadbfa)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #eadbfa, #eadbfa), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-2.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#eadbfa), to(#eadbfa)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #eadbfa, #eadbfa), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-3 {
  color: #ad356d;
}

.point-mapper__h1_trigger.theme-3.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd1e7), to(#ffd1e7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffd1e7, #ffd1e7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-3.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffd1e7), to(#ffd1e7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffd1e7, #ffd1e7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-4 {
  color: #f04f4f;
}

.point-mapper__h1_trigger.theme-4.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd7d7), to(#ffd7d7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffd7d7, #ffd7d7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-4.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffd7d7), to(#ffd7d7)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffd7d7, #ffd7d7), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-5 {
  color: #ff3a8c;
}

.point-mapper__h1_trigger.theme-5.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffe3fc), to(#ffe3fc)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffe3fc, #ffe3fc), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-5.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffe3fc), to(#ffe3fc)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffe3fc, #ffe3fc), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-6 {
  color: #ff893a;
}

.point-mapper__h1_trigger.theme-6.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffeadd), to(#ffeadd)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #ffeadd, #ffeadd), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-6.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#ffeadd), to(#ffeadd)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #ffeadd, #ffeadd), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-7 {
  color: #e7bc20;
}

.point-mapper__h1_trigger.theme-7.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff8e4), to(#fff8e4)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #fff8e4, #fff8e4), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-7.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#fff8e4), to(#fff8e4)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #fff8e4, #fff8e4), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-8 {
  color: #48c555;
}

.point-mapper__h1_trigger.theme-8.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cef3b2), to(#cef3b2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #cef3b2, #cef3b2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-8.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#cef3b2), to(#cef3b2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #cef3b2, #cef3b2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-9 {
  color: #2ccaad;
}

.point-mapper__h1_trigger.theme-9.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#cdfaf2), to(#cdfaf2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #cdfaf2, #cdfaf2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-9.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#cdfaf2), to(#cdfaf2)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #cdfaf2, #cdfaf2), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-10 {
  color: #0a8286;
}

.point-mapper__h1_trigger.theme-10.active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#d6eaeb), to(#d6eaeb)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, #d6eaeb, #d6eaeb), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-10.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left top, left bottom, from(#d6eaeb), to(#d6eaeb)), -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#f0f0f0));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #d6eaeb, #d6eaeb), linear-gradient(180deg, #f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.point-mapper__h1--default-closed {
  text-decoration: none;
}

.point-mapper__h1_trigger.is--toggle-all {
  padding-right: 72px;
}

.custom-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  margin-top: 0px;
  margin-right: 8px;
  margin-left: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
}

.custom-checkbox.w--redirected-checked {
  border-color: #39ad84;
  background-color: #39ad84;
  background-size: 20px 20px;
}

.custom-checkbox.point-data-toggle {
  width: 36px;
  height: 36px;
  margin-right: 4px;
  padding: 6px 2px 2px;
  background-color: hsla(0, 0%, 100%, 0);
}

.point-mapper__h2_source {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  margin-bottom: -2px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.85em;
  line-height: 100%;
}

.point-data__h1_icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  margin-right: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.point-mapper-content__list {
  height: auto;
}

.point-mapper-content__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ececec;
}

.point-data__header_icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  margin-left: 8px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.point-data__header_title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.2em;
  font-weight: 700;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.data-mapper-content {
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  padding: 22px 14px 14px 20px;
}

.data-mapper-content.narrow-scroll {
  z-index: 1;
  overflow: scroll;
  width: 300px;
}

.data-mapper-content__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ececec;
}

.data-mapper-content__list {
  position: relative;
  height: auto;
}

.data-mapper-content__list.is--loading {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.data-category {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.data-category__h2_trigger {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 32px;
  margin-bottom: 6px;
  padding-right: 8px;
  padding-left: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  font-size: 0.85em;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.data-category__h2_trigger:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

.data-category__h2_trigger.w--current {
  font-size: 1em;
}

.data-category__h1_wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
  padding-left: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.data-category__h3 {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
}

.data-category__h3.active {
  background-color: rgba(57, 173, 132, 0.2);
  color: #333;
  font-weight: 500;
}

.data-category__h3.active:hover {
  background-color: rgba(57, 173, 132, 0.3);
}

.data-category__h2_wrapper {
  position: relative;
  padding-left: 16px;
}

.data-category__h1_trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-left: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #f0f0f0;
  background-image: none;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.data-category__h1_trigger:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.05)), to(rgba(0, 0, 0, 0.05)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}

.data-category__h1_icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  margin-right: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
}

.data-category__h2 {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.data-category__h1_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.data-category__h1_toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 36px;
  height: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.data-category__h1_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.data-category__h2_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.data-category__h1_title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-right: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.toggle-icon-v--last {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.toggle-icon-v--last.is--open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.toggle-icon-v--last.is--closed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.toggle-icon-v--first {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.toggle-icon-v--first.is--open {
  display: none;
}

.toggle-icon-v--first.is--closed {
  display: none;
}

.data-mapper-content__description {
  margin-bottom: 16px;
  color: #707070;
  font-size: 0.9em;
}

.data-mapper-content__no-data {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  padding: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #666;
  font-size: 0.9em;
  font-weight: 500;
}

.no-data__icon {
  padding-right: 6px;
}

.point-mapper-content__description {
  margin-bottom: 16px;
  color: #707070;
  font-size: 0.9em;
}

.no-data {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #f0f0f0;
  color: #666;
  font-size: 0.9em;
}

.search__dropdown_scroll {
  overflow: auto;
}

.search__dropdown_scroll.narrow-scroll {
  margin-right: -4px;
  padding-right: 4px;
}

.map-options {
  position: relative;
  z-index: 999;
  display: block;
  width: 100%;
  margin-top: 1em;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 0 1px 6px -2px rgba(0, 0, 0, 0.3);
}

.map-options__legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
}

.map-options__filters {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}

.map-options__legend_wrap {
  display: -ms-grid;
  display: grid;
  height: 19px;
  margin-right: -2px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.map_legend-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 19px;
  margin-right: 2px;
  margin-left: 2px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 2px;
  background-color: #203761;
  font-size: 0.6em;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.map_legend-block.light {
  color: #fff;
}

.map-options__filters_header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 72px;
}

.filters__header_name {
  overflow: hidden;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-weight: 500;
}

.filters__header_icon {
  height: 24px;
  padding-right: 8px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.map-options__filters_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mapping-options__filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.mapping-options__filter.disabled {
  opacity: 0.4;
  cursor: auto;
}

.mapping-options__filter_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-bottom: 4px;
  color: #999;
  font-size: 0.7em;
  line-height: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.mapping-options__filter_menu {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 0.9em;
}

.map-options__legend_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
  font-size: 0.7em;
  line-height: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.filters__header_toggle {
  position: absolute;
  left: auto;
  top: 0%;
  right: 36px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 36px;
  height: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  cursor: pointer;
}

.filters__header_toggle:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.map-area {
  width: 100%;
}

.filter__header_sub-indicator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-right: 11px;
  padding-left: 11px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.filters__header_close {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 36px;
  height: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  cursor: pointer;
}

.filters__header_close:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.styles {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 99999999999;
  min-height: 100vh;
  background-color: #fff;
}

.main {
  position: relative;
  z-index: 1;
  height: 100vh;
}

.styles__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1024px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 48px;
  padding-bottom: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.styles__area_block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 33.333%;
  padding: 32px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.styles__area_block.wide {
  width: 100%;
}

.styles__area_block.horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.styles__area_block.horizontal.wide {
  position: relative;
}

.styles__area_block.test {
  min-width: auto;
}

.styles__section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.styles__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #b8b8b8;
  color: #000;
  font-size: 1.4em;
  font-weight: 700;
}

.hidden {
  display: none;
}

.data-category__h3_load-complete {
  position: absolute;
  left: auto;
  right: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.4;
}

.data-category__h3_load-complete.hidden {
  display: none;
}

.data-category__h3_loading {
  position: absolute;
  left: auto;
  right: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  opacity: 0.4;
}

.data-category__h3_loading.hidden {
  display: none;
}

.rich-data__shadow {
  position: absolute;
  left: auto;
  top: 0%;
  right: -16px;
  bottom: 0%;
  width: 16px;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.08)), color-stop(34%, rgba(0, 0, 0, 0.02)), to(transparent));
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.02) 34%, transparent);
}

.location-facility__name {
  overflow: hidden;
  margin-top: 4px;
}

.styles__subsection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 6px;
  padding: 32px 16px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #f0f0f0;
  color: #000;
  font-size: 1.2em;
  font-weight: 500;
}

.location__facilities_no-data {
  width: 100%;
  margin-right: 6px;
  margin-left: 6px;
}

.location__facilities_no-data.hidden {
  display: none;
}

.point-mapper-content__no-data {
  margin-bottom: 16px;
}

.location__sources_no-data {
  margin-right: 3px;
  margin-left: 3px;
}

.location-tag__name {
  width: 100%;
}

.location__facilities_loading {
  display: block;
  width: 100%;
  height: 32px;
  margin-right: 6px;
  margin-left: 6px;
}

.location__facilities_loading.hidden {
  display: none;
}

.loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 4px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 2px;
  background-color: #f0f0f0;
  color: #666;
  font-size: 0.9em;
}

.loading__icon {
  width: 24px;
  padding-right: 6px;
}

.location__sources_loading {
  margin-right: 3px;
  margin-left: 3px;
}

.point-mapper-content__loading {
  height: 32px;
  margin-bottom: 16px;
}

.data-mapper-content__loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
  font-weight: 500;
}

.data-mapper-content__loading.hidden {
  display: none;
}

.location-tag__loading {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  max-width: 140px;
  min-width: 60px;
  margin: 4px;
  padding-top: 3px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #707070;
  background-image: none;
  box-shadow: 0 2px 7px -4px #000;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.location-tag__loading:hover {
  border-color: #ccc;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color: #fff;
}

.location-tag__loading.hidden {
  display: none;
}

.location-facility__item_download {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0px 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
}

.map-tooltip__points {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  padding-top: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-top: 1px solid #e6e6e6;
  font-size: 0.85em;
}

.tooltip__points_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tooltip__notch {
  position: absolute;
  left: auto;
  right: auto;
  bottom: -7px;
  z-index: 0;
  width: 12px;
  height: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 3px 3px 8px -4px rgba(0, 0, 0, 0.5);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tooltip__value_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.2em;
  padding-right: 8px;
  padding-left: 4px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.map-tooltip__value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 180px;
  margin-top: 6px;
  padding-top: 6px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-top: 1px solid #e6e6e6;
  font-size: 0.85em;
  line-height: 1.4;
}

.map-tooltip__value.hidden {
  display: none;
}

.map-tooltip__name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  font-size: 0.85em;
  line-height: 1.4em;
  font-weight: 700;
  text-align: center;
}

.map-tooltip {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 260px;
  min-height: 32px;
  min-width: 120px;
  padding: 12px 10px 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 8px -4px rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.map-tooltip__geography-chip {
  position: absolute;
  left: auto;
  top: -8px;
  right: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 14px;
  padding: 2px 4px 2px 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  color: #fff;
  font-size: 0.65em;
  line-height: 100%;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
}

.tooltip__value_detail {
  margin-left: 3px;
  color: #999;
}

.tooltip__point_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px solid #ebebeb;
  line-height: 170%;
  font-weight: 500;
}

.tooltip__point_item.last {
  border-bottom-style: none;
}

.facility-tooltip__header_icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tooltip__facility-item_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  text-transform: capitalize;
}

.facility-tooltip__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
  line-height: 150%;
  font-weight: 500;
}

.facility-tooltip__item.last {
  border-bottom-style: none;
}

.facility-tooltip__close {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.63);
  cursor: pointer;
}

.facility-tooltip__close:hover {
  color: #000;
}

.facility-tooltip__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-top: 1px solid #e6e6e6;
  font-size: 0.85em;
}

.facility-tooltip__header_name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 0.85em;
  line-height: 1.4em;
  font-weight: 700;
}

.facility-tooltip__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 32px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.facility-tooltip {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 60vh;
  max-width: 350px;
  min-height: 32px;
  min-width: 110px;
  padding: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 8px -4px rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.facility-tooltip.demo {
  position: absolute;
  left: 37%;
  top: 15%;
}

.facility-tooltip.demo._2 {
  left: 16%;
}

.facility-tooltip.is--cluster {
  padding: 0px;
}

.tooltip__facility-item_value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
}

.map-options__legend_loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 19px;
  min-width: 60px;
  margin-right: 2px;
  margin-left: 2px;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 0.6em;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.map-options__legend_loading.hidden {
  display: none;
}

.legend-block__loading-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  margin-right: 4px;
  margin-left: 2px;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  font-size: 0.8em;
}

.sub-category-header__key-metrics_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.category-header__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.category-header__description {
  margin-top: 12px;
  margin-bottom: -6px;
  padding-top: 16px;
  border-top: 1px dashed #ccc;
  color: #333;
}

.login {
  position: absolute;
  display: -ms-grid;
  display: grid;
  width: 400px;
  padding: 16px 24px 24px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
}

.login__title {
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 4.5fr 1fr;
  grid-template-columns: 4.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 0.8em;
}

.login__close {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  margin-right: -12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
}

.login__close:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #39ad84;
}

.clear {
  margin: 0px;
  padding: 0px;
}

.login__description {
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.1);
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-image: none;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.02em;
  cursor: pointer;
}

.button:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.button.text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  color: #333;
  text-decoration: underline;
}

.button.button--grey {
  background-color: #f0f0f0;
  color: #333;
}

.button.button--margin-right {
  margin-right: 12px;
}

.button.button--subtle {
  height: auto;
  padding: 0.6em 0.7em;
  background-color: #f0f0f0;
  color: #333;
  font-weight: 400;
  text-decoration: none;
}

.login-form__actions {
  display: -ms-grid;
  display: grid;
  margin-top: 16px;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.login__form-checkbox {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.checkbox-label {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-field {
  border-radius: 2px;
}

.form-block {
  margin-bottom: 0px;
}

.tutorial {
  position: absolute;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  padding: 10px 24px 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
}

.tutorial__title {
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 4.5fr 1fr;
  grid-template-columns: 4.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 0.7em;
}

.tutorial__close {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  margin-right: -12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
}

.tutorial__slider {
  height: 400px;
  margin-right: -24px;
  margin-bottom: 62px;
  margin-left: -24px;
  background-color: #fff;
}

.left-arrow {
  left: 0%;
  top: auto;
  right: auto;
  bottom: -62px;
  width: 20%;
  height: 56px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.1em;
  cursor: default;
}

.right-arrow {
  left: auto;
  top: auto;
  right: 0%;
  bottom: -62px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: 56px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.1em;
}

.tutorial__slide-content {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide-nav {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -32px;
  display: none;
  height: 32px;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.tutorial__slide-info {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 12px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #333;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  line-height: 140%;
  text-align: left;
}

.tutorial__slide_button {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  margin-right: 24px;
  margin-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
  cursor: pointer;
}

.tutorial__slide_button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

.tutorial__slide_button.next {
  margin-left: 12px;
  background-color: #707070;
  color: #fff;
}

.tutorial__slide_button.next:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.tutorial__slide_button.previous {
  width: 44px;
  height: 44px;
  margin-right: 12px;
}

.tutorial__slide_gradient {
  position: absolute;
  z-index: 1;
  height: 40px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), color-stop(103%, transparent));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent 103%);
}

.tutorial__slide_gradient.bottom {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.1)), color-stop(103%, transparent));
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent 103%);
}

.tutorial__dismiss {
  display: -ms-grid;
  display: grid;
  margin-top: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1.75fr 0.75fr;
  grid-template-columns: 1.75fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dismiss__no-show {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__link_icon {
  margin-left: 6px;
}

.login-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.login-modal.webflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.login-modal.hidden {
  display: none;
}

.tutorial-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: none;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.tutorial-modal.webflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav__link_text {
  margin-left: 6px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.html-embed {
  height: 24px;
}

.sticky-header__tutorial {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-top: 16px;
  margin-right: -64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  color: #39ad84;
  cursor: pointer;
}

.sticky-header__tutorial:hover {
  background-color: rgba(57, 173, 132, 0.06);
}

.sticky-header__tutorial.hidden {
  display: none;
}

.location__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.location__tutorial {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  font-size: 0.85em;
  cursor: pointer;
}

.location__tutorial:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.location__tutorial:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.location__tutorial.hidden {
  display: none;
}

.location__tutorial_icon {
  margin-right: 2px;
}

.location__tutorial_text {
  margin-right: 2px;
  margin-left: 2px;
}

.tutorial__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tutorial__slide-number {
  position: absolute;
  left: 24px;
  top: auto;
  right: auto;
  bottom: 16px;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 6px -3px #000;
  color: #fff;
}

.slide-info__title {
  display: inline-block;
  padding: 1px 4px;
  border-radius: 2px;
  font-weight: 500;
}

.map-about {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  z-index: 998;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-right: 8px;
  padding-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #fff;
  font-size: 0.85em;
  cursor: pointer;
}

.map-about:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.map-about.hidden {
  display: none;
}

.map-about__profile-name {
  margin-left: 3px;
}

.container {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}

.page-header {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.map-about__icon {
  margin-right: 4px;
}

.nav__link-wrap {
  margin-right: -12px;
}

.login-form__checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 6px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-block {
  padding-right: 48px;
  padding-bottom: 60px;
  padding-left: 48px;
}

.content-block.first {
  margin-top: 56px;
  padding-top: 60px;
}

.content-block.bg-green {
  padding-bottom: 40px;
  background-color: rgba(57, 173, 132, 0.05);
}

.content-block.bg-green.first {
  margin-bottom: 40px;
  padding-top: 40px;
}

.content-block.footer {
  margin-top: auto;
}

.page-header__overline {
  font-weight: 500;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.page-header__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.page-header__website-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  padding: 5px 6px 4px 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(57, 173, 132, 0.2);
  color: #39ad84;
  text-decoration: none;
}

.page-header__website-link:hover {
  background-color: #39ad84;
  color: #fff;
}

.page-header__website-link.hidden {
  display: none;
}

.page-header__website-link_icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f0f0f0;
}

.footer-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.footer-logo {
  height: 44px;
}

.footer-links {
  margin-top: 16px;
}

.footer-links__header {
  margin-bottom: 16px;
  color: #39ad84;
  font-size: 0.85em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.footer-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #707070;
  text-decoration: none;
}

.footer__disclaimer {
  display: -ms-grid;
  display: grid;
  margin-top: 16px;
  padding-top: 32px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.85em;
}

.location__description {
  margin-top: -16px;
  margin-bottom: 24px;
  color: #707070;
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.map-options__context {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  font-size: 0.8em;
}

.map-options__context.hidden {
  display: none;
}

.map-option__context_text {
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #707070;
}

.map-options__context_tooltip {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  min-height: 36px;
  margin-right: -11px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.map-options__context_tooltip:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.tooltip {
  position: absolute;
  top: 0px;
  display: none;
  max-width: 300px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.tooltip-card {
  position: absolute;
  top: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.63);
}

.tooltip-card.dark {
  background-color: #333;
  color: #fff;
}

.tooltip-notch {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: -4px;
  width: 8px;
  height: 8px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, 0.63);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tooltip-notch.dark {
  background-color: #333;
}

.tooltip-text {
  min-width: 160px;
  text-align: center;
}

.profile-logo {
  max-height: 40px;
  margin: auto;
}

.content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.data-category__h3_trigger {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-bottom: 6px;
  padding-right: 8px;
  padding-left: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #666;
  font-size: 0.85em;
  text-decoration: none;
  cursor: pointer;
}

.data-category__h3_trigger:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.data-category__h3_trigger.active {
  color: #333;
  font-weight: 500;
}

.data-category__h3_trigger.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.data-category__h3_content {
  position: relative;
}

.data-category__h3_wrapper {
  position: relative;
  margin-top: 2px;
  margin-bottom: 10px;
  padding-left: 16px;
}

.data-category__h4_line-v {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 1px;
  margin-bottom: 15px;
  margin-left: 4px;
  background-color: rgba(0, 0, 0, 0.11);
}

.data-category__h4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.03);
  color: #666;
  font-size: 0.85em;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.data-category__h4:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: #000;
}

.data-category__h4.active {
  color: #333;
  font-weight: 500;
}

.data-category__h4.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.data-category__h4_line-h {
  position: absolute;
  left: -16px;
  z-index: 1;
  width: 8px;
  height: 1px;
  margin-left: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.data-category__h4_loading {
  position: absolute;
  left: auto;
  right: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  opacity: 0.4;
}

.data-category__h4_loading.hidden {
  display: none;
}

.data-category__h4_load-complete {
  position: absolute;
  left: auto;
  right: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.4;
}

.data-category__h4_load-complete.hidden {
  display: none;
}

.bar-chart__tooltip_name {
  color: #333;
}

.panel-toggle__tooltip {
  position: absolute;
  left: 100%;
  top: auto;
  right: auto;
  bottom: auto;
  display: none;
  height: 28px;
  margin-left: 12px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
  color: #333;
  font-size: 0.9em;
  white-space: nowrap;
}

.panel-toggle__tooltip_text {
  font-weight: 500;
}

.panel-toggle__tooltip_notch {
  position: absolute;
  left: -4px;
  top: auto;
  right: auto;
  bottom: auto;
  width: 8px;
  height: 8px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.location__breadcrumbs_title {
  width: 100%;
  margin-left: 3px;
  color: #cacaca;
  font-size: 0.85em;
}

.print-styling {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 0px;
}

.tooltip__value_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px 6px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
}

.location__facilities_trigger {
  position: -webkit-sticky;
  position: sticky;
  top: 77px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
}

.location__facilities_trigger.hidden {
  display: none;
}

.location__facilities_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.location__facilities_content.is--hidden {
  height: 0px;
}

.location__facilities_content.is--shown {
  height: auto;
}

.location__facilities_icon {
  margin-right: 8px;
}

.location__facilities_expand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-image: none;
  color: #fff;
  cursor: pointer;
}

.location__facilities_expand:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.location__facilities_expand.bg-primary.hidden {
  display: none;
}

.location__facilities_contract {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-image: none;
  color: #fff;
  cursor: pointer;
}

.location__facilities_contract:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.location__facilities_contract.bg-primary.hidden {
  display: none;
}

.location__facilities_content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 16px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.location__facilities_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.location__facilities_header.hidden {
  display: none;
}

.location__facilities_title_loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 140%;
  text-transform: none;
}

.location__facilities_header--loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  margin-left: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.location__facilities_header--loading.hidden {
  display: none;
}

.map-geo-select {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  min-height: 44px;
  margin-right: 50px;
  margin-bottom: 25px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 0 1px 6px -2px rgba(0, 0, 0, 0.3);
}

.mag-geo__dropdown_wrap {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 6px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mag-geo__dropdown_wrap.disabled {
  opacity: 0.4;
}

.warning-modal {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.warning-modal.hidden {
  display: none;
}

.warning {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  padding: 16px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
}

.warning__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.warning-title {
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 4.5fr 1fr;
  grid-template-columns: 4.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 0.7em;
}

.warning__actions {
  display: -ms-grid;
  display: grid;
  padding-top: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #f0f0f0;
}

.button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -12px;
}

.point-mapper__h1_arrow-toggle {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 36px;
  height: 36px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-2 {
  width: 36px;
  height: 36px;
}

.fas {
  width: auto;
}

.point-mapper__arrow-down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.point-mapper__arrow-up {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.point-mapper__h1_trigger-arrow {
  position: absolute;
  left: auto;
  top: auto;
  right: 0.3em;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #333;
}

.map-download {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  margin-bottom: 84px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.map-download.hover-text-primary.disabled {
  margin-bottom: 100px;
}

.map-download.disabled {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  color: #9e9e9e;
  cursor: auto;
}

.map-download.disabled:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  color: #9e9e9e;
}

.plate {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.plate.bg-primary {
  background-color: #39ad84;
}

.bg-primary {
  background-color: #39ad84;
}

.bg-secondary {
  background-color: #3950ad;
}

.text-primary {
  color: #39ad84;
}

.hover-bg-primary-light:hover {
  background-color: rgba(57, 173, 132, 0.2);
}

.bg-primary-light {
  background-color: rgba(57, 173, 132, 0.2);
}

.hover-text-primary:hover {
  color: #39ad84;
}

.tab-notice {
  position: fixed;
  left: auto;
  top: auto;
  right: 22px;
  bottom: 0%;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 150px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  color: #fff;
  font-weight: 700;
  text-align: right;
  text-decoration: none;
}

.tab-notice.hidden {
  display: none;
}

.tab-notice__text {
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.tab-notice__inner {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 44px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  white-space: nowrap;
  cursor: pointer;
}

.tab-notice__inner:hover {
  padding-bottom: 52px;
}

.tab-notice__content {
  padding: 12px;
  color: #fff;
}

.point-mapper-content__error {
  margin-bottom: 16px;
}

.error {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #f0f0f0;
  color: #666;
  font-size: 0.9em;
}

.error__icon {
  padding-right: 6px;
}

.facility-tooltip__scroll-wrapper {
  overflow: auto;
  height: 100%;
  margin-top: 6px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.facility-tooltip__open-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 32px;
  margin-top: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  font-size: 0.85em;
  text-align: center;
  cursor: pointer;
}

.facility-tooltip__open-modal:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.facility-tooltip__showing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 8px;
  border-top: 1px solid #ebebeb;
  font-size: 0.85em;
}

.facility-tooltip__showing_icon {
  width: 24px;
  height: 24px;
}

.facility-info {
  position: absolute;
  left: 20%;
  top: auto;
  right: 20%;
  bottom: 20px;
  z-index: 5;
  display: none;
  max-height: 40vh;
  max-width: 840px;
  margin-right: auto;
  margin-left: auto;
  padding: 16px 12px 16px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.facility-info.webflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.facility-info__title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.facility-info__close {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.63);
  cursor: pointer;
}

.facility-info__close:hover {
  color: #000;
}

.facility-info__close.hidden {
  display: none;
}

.facility-info__content {
  overflow: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.facility-info__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 6px;
  padding-top: 4px;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-top: 1px solid #e6e6e6;
  font-size: 0.85em;
}

.facility-info__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
  line-height: 150%;
  font-weight: 500;
}

.facility-info__item.last {
  border-bottom-style: none;
}

.facility-info__item_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  text-transform: capitalize;
}

.facility-info__item_value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
}

.facility-info__header {
  display: -ms-grid;
  display: grid;
  margin-bottom: 10px;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.facility-info__print {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.63);
  cursor: pointer;
}

.facility-info__print:hover {
  color: #000;
}

.facility-info__print.hidden {
  display: none;
}

.map-title {
  position: absolute;
  left: auto;
  top: 16px;
  right: auto;
  bottom: auto;
  z-index: 999;
  display: block;
  margin-right: 24px;
  margin-left: 24px;
  padding: 12px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  font-size: 1.2em;
  font-weight: 700;
}

.map-title.webflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.map-title.hidden {
  display: none;
}

.rich-data__print {
  position: absolute;
  left: auto;
  top: 82px;
  right: 12px;
  bottom: auto;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f0f0f0;
  color: #333;
}

.rich-data__print:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.map-print {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 24px;
  z-index: 999;
  width: 95%;
  max-width: 650px;
  justify-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.map-print.hidden {
  display: none;
}

.map-point-legend {
  position: relative;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -6px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.map-point-legend.hidden {
  display: none;
}

.map-print__choropleth-legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 6px -2px rgba(0, 0, 0, 0.3), 0 0 0 -1px rgba(0, 0, 0, 0.2);
}

.map-print__legend_label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
  font-size: 0.7em;
  line-height: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.map-print__legend_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 19px;
  margin-right: -2px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.map-print__legend_loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 19px;
  min-width: 60px;
  margin-right: 2px;
  margin-left: 2px;
  padding-right: 4px;
  padding-left: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 0.6em;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.map-print__legend_loading.hidden {
  display: none;
}

.point-legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 0 1px 6px -2px rgba(0, 0, 0, 0.3);
  font-size: 13px;
}

.point-legend__color {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.06);
}

.point-legend__color.demo {
  background-color: #39ad84;
}

.point-legend__text {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.map-bottom-items {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 30px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 95%;
  max-width: 650px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.map-bottom-items.hidden {
  display: none;
}

.point-legend__remove {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #707070;
  cursor: pointer;
}

.point-legend__remove:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
}

.point-legend__remove.hidden {
  display: none;
}

.map-print__point-legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -6px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.location__facilities_google-link {
  white-space: nowrap;
}

.facility-info__google-map-text {
  width: 100%;
}

.facility-info__view-google-map {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 6px 10px 6px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  text-decoration: none;
}

.facility-info__view-google-map:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

.facility-info__view-google-map.hidden {
  display: none;
}

.facility-info__google-map-icon {
  margin-right: 4px;
}

.profile-indicator__table {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profile-indicator__table_row {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 33.333333333%;
  -ms-grid-columns: 33.333333333%;
  grid-template-columns: 33.333333333%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  font-size: 13px;
}

.profile-indicator__table_row:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.profile-indicator__table_row.profile-indicator__table_row--header {
  grid-auto-columns: 33.333333333%;
  border-bottom: 1px solid #707070;
  background-color: hsla(0, 0%, 100%, 0.06);
  box-shadow: 0 10px 10px -9px rgba(0, 0, 0, 0.1);
  font-weight: 700;
}

.profile-indicator__table_row.profile-indicator__table_row--alt {
  background-color: hsla(0, 0%, 100%, 0.06);
}

.profile-indicator__table_row.profile-indicator__table_row--2-col {
  grid-auto-columns: minmax(120px, 50%);
  -ms-grid-columns: minmax(120px, 50%);
  grid-template-columns: minmax(120px, 50%);
}

.profile-indicator__table_cell {
  overflow: hidden;
  padding: 8px;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  white-space: nowrap;
}

.profile-indicator__table_cell.profile-indicator__table_cell--first {
  border-left-style: none;
}

.profile-indicator__table_content {
  max-height: 300px;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profile-indicator__table_show-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profile-indicator__table_show-more.hidden {
  display: none;
}

.profile-indicator__table_showing {
  margin-top: 1em;
  font-size: 13px;
}

.profile-indicator__table_load-more {
  width: 100%;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
}

.profile-indicator__table_inner {
  overflow: hidden;
  max-height: 400px;
  margin-bottom: 2em;
}

.map__legend-block_value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.location__facilities_download-all {
  display: -ms-grid;
  display: grid;
  height: 36px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr -webkit-max-content;
  -ms-grid-columns: 1fr max-content;
  grid-template-columns: 1fr -webkit-max-content;
  grid-template-columns: 1fr max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.location__facilities_download-all:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.location__facilities_download-all.location__facilities_download-all--footer {
  display: none;
  margin-top: 8px;
  margin-left: 8px;
}

.location__facilities_header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.point-mapper-options {
  z-index: 999;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 0 1px 6px -2px rgba(0, 0, 0, 0.3);
}

.point-mapper__filters {
  border-bottom: 1px solid #ebebeb;
}

.point-mapper__legend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
}

.point-mapper-options__filters_header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 10px;
}

.point-mapper-options__filters_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-right: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.point-mapper-options__filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding-bottom: 10px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.point-mapper-options__filter.disabled {
  opacity: 0.4;
}

.point-mapper-options__current-filters {
  width: 100%;
}

.div-block-2 {
  width: 100%;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.map-options__filter-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-right: 46px;
  padding-left: 10px;
}

.map-options__filter-row.hidden {
  display: none;
}

.mapping-options__remove-filter {
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  cursor: pointer;
}

.mapping-options__remove-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mapping-options__remove-filter:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.mapping-options__remove-filter.is--hidden {
  display: none;
  background-color: rgba(0, 0, 0, 0.06);
  color: #c5c5c5;
  cursor: auto;
}

.map-options__filter-labels {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-right: 46px;
  padding-left: 10px;
}

.map-options__filter-labels.hidden {
  display: none;
}

.mapping-options__add-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  font-size: 0.9em;
  text-decoration: none;
}

.mapping-options__add-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mapping-options__add-filter:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.mapping-options__add-filter.hidden {
  display: none;
}

.profile-indicator__filters-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  padding-top: 5px;
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.profile-indicator__filter-labels {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-right: 43px;
}

.profile-indicator__filter-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
}

.profile-indicator__add-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  margin-top: 10px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  text-decoration: none;
}

.profile-indicator__add-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.profile-indicator__add-filter:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.profile-indicator__filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.profile-indicator__filter.disabled {
  opacity: 0.4;
  cursor: auto;
}

.profile-indicator__filter_menu {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 0.9em;
}

.profile-indicator__remove-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  cursor: pointer;
}

.profile-indicator__remove-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.profile-indicator__remove-filter:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.profile-indicator__remove-filter.is--hidden {
  display: none;
  background-color: rgba(0, 0, 0, 0.06);
  color: #c5c5c5;
  cursor: auto;
}

.profile-indicator__new-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  margin-top: 10px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.profile-indicator__new-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.profile-indicator__new-filter:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.profile-indicator__new-filter.is--hidden {
  display: none;
  background-color: rgba(0, 0, 0, 0.06);
  color: #c5c5c5;
  cursor: auto;
}

.profile-indicator__filter-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 52px;
  margin-left: -5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.text-block-3 {
  padding-right: 6px;
}

.walkthrough {
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.19);
}

.walkthrough.is--right {
  position: absolute;
  left: 100%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 2.6em;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  padding: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 2px;
  color: #fff;
  font-size: 13px;
}

.walkthrough.is--search-walkthrough {
  position: absolute;
  left: 0%;
  top: 100%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1em;
  padding-right: 0.5em;
  padding-left: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
}

.walkthrough.is--below {
  position: absolute;
  top: 100%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 2.6em;
  margin-top: 1em;
  padding: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 2px;
  color: #fff;
  font-size: 13px;
}

.is--walkthrough-text {
  white-space: nowrap;
}

.walkthrough-chip {
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.walkthrough-chip.is--left {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: auto;
  margin-left: -4px;
}

.walkthrough-chip.is---top {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: auto;
  margin-top: -4px;
}

.profile-indicator__new-filter-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  margin-top: 10px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  font-size: 0.9em;
  text-decoration: none;
}

.profile-indicator__new-filter-2:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.profile-indicator__new-filter-2:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.point-mapper__h2_facet {
  display: none;
  margin-right: 0.5em;
  margin-bottom: 0px;
  margin-left: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 0.8em;
}

.point-mapper__h2_facet.is--shown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.point-mapper__h1_facet {
  display: none;
  margin-right: 0.5em;
  margin-bottom: 0px;
  margin-left: auto;
  padding-right: 0.5em;
  padding-left: 0.5em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 0.8em;
}

.point-mapper__h1_facet.is--shown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.documentation__container {
  display: -ms-grid;
  display: grid;
  max-width: 1140px;
  margin-right: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.documentation__label {
  width: 100%;
  margin-bottom: 5px;
  color: #000;
  font-size: 11px;
  line-height: 1.4em;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.documentation__label.documentation__label--light {
  font-weight: 400;
}

.styles__class-label {
  margin-top: 4px;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 4px 10px;
  border-radius: 6px;
  background-color: #f5f5f5;
  font-family: Inconsolata, monospace;
  color: #222;
}

.styles__class-label.styles__class-label--primary {
  background-color: #222;
  color: #dadada;
}

.documentation__component-wrap {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #e6e6e6;
}

.documentation__component-wrap.is--75 {
  width: 75%;
}

.documentation__component-wrap.is--50 {
  width: 50%;
}

.documentation__main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.documentation__section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 70px 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.documentation__grid-4-col {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin-bottom: 10px;
  justify-items: start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.documentation__class-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.documentation__menu_inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  height: 100%;
  padding-right: 24px;
  padding-left: 20%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.documentation__h2 {
  margin-bottom: 20px;
  color: #a1a1a1;
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.documentation__menu {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 20%;
  height: 100vh;
  min-width: 250px;
  padding-top: 70px;
  padding-bottom: 24px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f5f5f5;
}

.documentation__h1 {
  margin-bottom: 30px;
  font-size: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.documentation__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.documentation__block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 4px;
}

.documentation__block.is--full {
  padding-bottom: 2em;
}

.documentation__h3 {
  margin-bottom: 10px;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.point-filters__filters {
  width: 100%;
}

.point-filters_header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 72px;
}

.point-filters__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-right: 11px;
  padding-left: 11px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.point-filters__header-toggle {
  position: absolute;
  left: auto;
  top: 0%;
  right: 36px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 36px;
  height: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  cursor: pointer;
}

.point-filters__header-toggle:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.point-filters__header-toggle.is--v2 {
  right: 0px;
}

.point-filters__header-close {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 36px;
  height: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  cursor: pointer;
}

.point-filters__header-close:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.point-filters__labels {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-right: 46px;
  padding-left: 10px;
}

.point-filters__labels.hidden {
  display: none;
}

.point-filters_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.point-filters__filter-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-right: 46px;
  padding-left: 10px;
}

.point-filters__filter-row.hidden {
  display: none;
}

.point-filters__add-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  font-size: 0.9em;
  text-decoration: none;
}

.point-filters__add-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.point-filters__add-filter:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.point-filters__add-filter.hidden {
  display: none;
}

.point-filters__filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 48%;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.point-filters__filter.disabled {
  opacity: 0.4;
  cursor: auto;
}

.point-filters__filter-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-bottom: 4px;
  color: #999;
  font-size: 0.7em;
  line-height: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.point-filters__filter-menu {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 0.9em;
}

.point-filters__remove-filter {
  position: absolute;
  left: auto;
  top: auto;
  right: 10px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  cursor: pointer;
}

.point-filters__remove-filter:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.point-filters__remove-filter:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.point-filters__remove-filter.is--hidden {
  display: none;
  background-color: rgba(0, 0, 0, 0.06);
  color: #c5c5c5;
  cursor: auto;
}

.point-filters {
  position: relative;
  z-index: 999;
  display: block;
  width: 100%;
  margin-top: 1em;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, 0.2), 0 1px 6px -2px rgba(0, 0, 0, 0.3);
}

.point-filters.hidden {
  display: none;
}

.documention__description {
  margin-bottom: 2em;
  font-size: 0.9em;
}

.facility-tooltip__cluster {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 16em;
  margin-right: -0.2em;
  margin-left: -0.2em;
  padding-right: 0.2em;
  padding-left: 0.2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-bottom: 1px solid #fff;
  font-size: 0.85em;
}

.tooltip__cluster-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0.4em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  color: #333;
  text-decoration: none;
  cursor: default;
}

.tooltip__cluster-item.is--last {
  border-bottom-style: none;
  border-bottom-color: hsla(0, 0%, 90.2%, 0);
}

.tooltip__cluster-title {
  margin-bottom: 0px;
}

.tooltip__cluster-title.truncate {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tootlip__cluster-facet {
  margin-bottom: 0px;
  padding-left: 1.2em;
}

.tooltip__cluster-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: #707070;
}

.tooltip__cluster-icon.is--demo-1 {
  background-color: #39ad84;
}

.tooltip__cluster-icon.is--demo-2 {
  background-color: #f04f4f;
}

.tooltip__cluster-icon.is--demo-3 {
  background-color: #ff3a8c;
}

.tooltip__cluster-icon_letters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  padding-top: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  font-size: 0.9em;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

.tooltip__cluster-icon_letters.is--hidden {
  display: none;
}

.location-facilities__trigger--loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-image: none;
  color: #fff;
  cursor: pointer;
}

.location-facilities__trigger--loading:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.location-facilities__trigger--loading.bg-primary.hidden {
  display: none;
}

.location-facility__item--loading {
  width: 16px;
  margin-right: 4px;
}

.profile-indicator__version {
  width: 100%;
  margin-top: -1px;
  margin-bottom: -1px;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  border-top: 1px dashed #e6e6e6;
  border-bottom: 1px dashed #e6e6e6;
  font-size: 0.85em;
  line-height: 2;
}

.version-link {
  display: inline-block;
  height: 1.8em;
  margin-right: 0.2em;
  margin-left: 0.2em;
  padding: 0em 0.4em;
  border-radius: 2px;
  background-color: #f0f0f0;
  background-image: none;
  color: #333;
  line-height: 1.8;
}

.version-link:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.version-link.is--current {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.version-link.no-link {
  cursor: default;
}

.version-link.no-link:hover {
  background-image: none;
}

.generic-modal {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.generic-modal.hidden {
  display: none;
}

.generic-modal.is--demo {
  position: static;
  width: auto;
  height: 300px;
}

.modal {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  padding: 16px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
}

.modal__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal__actions {
  display: -ms-grid;
  display: grid;
  padding-top: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #f0f0f0;
}

.modal-title {
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 4.5fr 1fr;
  grid-template-columns: 4.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 0.7em;
}

.highlight {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #39ad84;
  border-radius: 4px;
  box-shadow: 0 0 7px 2px #a3d8c5;
}

.highlight.hidden {
  display: none;
}

.highlight__notification {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 0.3em 0.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #39ad84;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
}

.highlight__notification.is--align-right {
  right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.highlight__notification.is--align-left {
  left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.highlight__notification.hidden {
  display: none;
}

.highlight__chip {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 8px;
  height: 8px;
  margin-bottom: -4px;
  background-color: #39ad84;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.highlight__chip.is--align-right {
  right: 0px;
  margin-right: 8px;
}

.highlight__chip.is--align-left {
  left: 0px;
  margin-left: 8px;
}

.highlight__message {
  margin-bottom: 0px;
}

.highlight-dummy {
  position: relative;
  width: 200px;
  height: 100px;
  margin-top: 3em;
}

.facility-tooltip__scroll {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 8px;
}

.map-bottom-items--v2 {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 25px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 95%;
  max-width: 650px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.map-bottom-items--v2.hidden {
  display: none;
}

.point-filters__loading {
  margin-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.loading-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 4px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 2px;
  background-color: #f0f0f0;
  color: #666;
  font-size: 0.9em;
}

.point-filters__no-data {
  margin-top: 5px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #707070;
}

.point-filters__no-data.hidden {
  display: none;
}

.map-credit {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  z-index: 1000;
  margin-bottom: 0px;
  padding: 5px 8px;
  background-color: hsla(0, 0%, 100%, 0.5);
  font-size: 0.9em;
  line-height: 1;
  white-space: nowrap;
}

.map-options__loading {
  margin-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.map-options__no-data {
  margin-top: 5px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #707070;
}

.map-options__context--v2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  font-size: 0.8em;
}

.map-options__context--v2.hidden {
  display: none;
}

.data-mapper-content__list_loading {
  position: absolute;
  left: -6px;
  top: -6px;
  right: -6px;
  bottom: 0%;
  z-index: 100;
  display: none;
  overflow: hidden;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.03);
}

.data-mapper-content__list_loading-grad {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 50%;
  height: 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(50%, rgba(0, 0, 0, 0.1)), color-stop(102%, transparent));
  background-image: linear-gradient(270deg, transparent, rgba(0, 0, 0, 0.1) 50%, transparent 102%);
}

.data-mapper--v2 {
  position: fixed;
  left: 0%;
  top: 56px;
  right: auto;
  bottom: 0%;
  z-index: 3;
  display: none;
  min-width: 300px;
  background-color: #fff;
}

.data-mapper--v2.webflow {
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.data-category__h1_trigger--v2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding-left: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #f0f0f0;
  background-image: none;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.data-category__h1_trigger--v2:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.05)), to(rgba(0, 0, 0, 0.05)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}

.data-category__h1_trigger--v2.bg-primary {
  background-color: #39ad84;
}

.data-category__h2_trigger--v2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 32px;
  margin-bottom: 6px;
  padding-right: 8px;
  padding-left: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #333;
  font-size: 0.85em;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.data-category__h2_trigger--v2:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

.data-category__h2_trigger--v2.w--current {
  font-size: 1em;
}

.data-category__h3_trigger--v2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-bottom: 6px;
  padding-right: 8px;
  padding-left: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  color: #666;
  font-size: 0.85em;
  text-decoration: none;
  cursor: pointer;
}

.data-category__h3_trigger--v2:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.data-category__h3_trigger--v2.active {
  color: #333;
  font-weight: 500;
}

.data-category__h3_trigger--v2.active:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.data-mapper-content__list--v2 {
  position: relative;
  height: auto;
}

.data-mapper-content__list--v2.is--loading {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.data-category__h1_content--v2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: max-height 400ms cubic-bezier(.23, 1, .32, 1);
  transition: max-height 400ms cubic-bezier(.23, 1, .32, 1);
}

.data-category__h1_content--v2.is--closed {
  max-height: 0px;
}

.data-category__h2_content--v2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: max-height 400ms ease;
  transition: max-height 400ms ease;
}

.data-category__h2_content--v2.is--closed {
  max-height: 0px;
}

.data-category__h3_content--v2 {
  position: relative;
  -webkit-transition: height 400ms ease;
  transition: height 400ms ease;
}

.data-category__h3_content--v2.is--closed {
  height: 0px;
}

.data-category--v2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.tutorial-modal--v2 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: none;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.tutorial-modal--v2.webflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tutorial__slide-image--v2 {
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 0%;
  background-size: cover;
}

.tutorial__slide-image--v2._1 {
  position: relative;
  height: 350px;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
}

.tutorial__slide-image--v2._2 {
  background-image: url('../images/slide-2.jpg');
}

.tutorial__slide-image--v2._4 {
  background-image: url('../images/slide-3.jpg');
  background-position: 0% 0%;
}

.tutorial__slide-image--v2._3 {
  background-image: url('../images/slide-4.jpg');
  background-position: 0% 0%;
}

.tutorial__slide-image--v2._5 {
  background-image: url('../images/slide-5.jpg');
  background-position: 0% 0%;
}

.tutorial__slide-image--v2._2b {
  background-image: url('../images/slide-2b.jpg');
}

.tutorial__slide-image--v2._7 {
  background-image: url('../images/slide-7.jpg');
  background-position: 50% 100%;
}

.panel-toggle__tooltip-2 {
  position: absolute;
  left: 100%;
  top: auto;
  right: auto;
  bottom: auto;
  display: none;
  height: 28px;
  margin-left: 12px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
  color: #333;
  font-size: 0.9em;
  white-space: nowrap;
}

.tutorial__slide-info-2 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 12px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #333;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  line-height: 140%;
  text-align: left;
}

.tutorial__slide-image {
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 0%;
  background-size: cover;
}

.tutorial__slide-image._2b {
  background-image: url('../images/slide-2b.jpg');
}

.tutorial__slide-image._3 {
  background-image: url('../images/slide-4.jpg');
  background-position: 0% 0%;
}

.tutorial__slide-image._5 {
  background-image: url('../images/slide-5.jpg');
  background-position: 0% 0%;
}

.tutorial__slide-image._7 {
  background-image: url('../images/slide-7.jpg');
  background-position: 50% 100%;
}

.tutorial__slide-image._4 {
  background-image: url('../images/slide-3.jpg');
  background-position: 0% 0%;
}

.tutorial__slide-image._2 {
  background-image: url('../images/slide-2.jpg');
}

.tutorial__slide-image._1 {
  position: relative;
  height: 350px;
  background-image: url('../images/slide-1.jpg');
  background-position: 50% 0%;
  background-size: cover;
}

.tutorial-2 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  padding: 10px 24px 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
}

.tutorial__slide_button-2 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  margin-right: 24px;
  margin-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
  cursor: pointer;
}

.tutorial__slide_button-2:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

.tutorial__slide_button-2.next {
  margin-left: 12px;
  background-color: #707070;
  color: #fff;
}

.tutorial__slide_button-2.next:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.tutorial__slide_button-2.previous {
  width: 44px;
  height: 44px;
  margin-right: 12px;
}

.data-compare {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  padding: 22px 14px 14px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 1em;
  background-color: #fff;
}

.menu-h2 {
  font-size: 1em;
  line-height: 1;
}

.data-compare__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-text {
  margin-bottom: 0px;
  line-height: 1;
}

.compare-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 0.5em;
}

.compare-list__item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 2px;
  background-color: #f0f0f0;
}

.compare-item__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0.8em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.95em;
  text-decoration: none;
}

.compare-item__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 0.5em;
}

.compare-item__text {
  margin-bottom: 0px;
  line-height: 1;
  letter-spacing: -0.02em;
}

.compare-item__expand-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-image: none;
}

.compare-item__expand-trigger:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.compare-item__remove {
  position: absolute;
  left: auto;
  top: -0.8em;
  right: -0.8em;
  bottom: auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  color: #666;
}

.compare-item__remove:hover {
  color: #000;
}

@media screen and (max-width: 991px) {
  .rich-data-content {
    padding-top: 44px;
    padding-right: 44px;
    padding-left: 44px;
  }

  .body {
    font-size: 14px;
  }

  .rich-data-nav {
    width: 44px;
  }

  .rich-data-nav__list {
    padding-top: 72px;
  }

  .location__facilities {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .location-facility {
    width: 50%;
  }

  .rich-data-bumper {
    width: 44px;
  }

  .rich-data-nav__toggle {
    display: none;
  }

  .key-metric {
    width: 50%;
  }

  .bar-chart__labels {
    padding-left: 90px;
  }

  .bar-chart__underline {
    margin-left: 90px;
  }

  .bar-chart {
    font-size: 0.9em;
  }

  .profile-indicator__chart_body {
    padding-right: 12px;
  }

  .bar-chart__x-label {
    padding-left: 90px;
  }

  .bar-chart__row_label {
    width: 90px;
  }

  .page-header {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .content-block {
    padding-bottom: 40px;
  }

  .content-block.bg-green.first {
    margin-bottom: 40px;
    padding-top: 40px;
  }

  .profile-indicator__table_row {
    grid-auto-columns: 33.333333333%;
  }

  .profile-indicator__table_showing {
    font-size: 13px;
  }

  .location__facilities_download-all.location__facilities_download-all--header {
    display: none;
  }

  .location__facilities_download-all.location__facilities_download-all--footer {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 4px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr -webkit-max-content;
    -ms-grid-columns: 1fr max-content;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.4em;
  }

  .rich-data-content {
    padding-top: 32px;
    padding-right: 32px;
    padding-left: 32px;
  }

  .nav.narrow {
    padding-right: 32px;
    padding-left: 32px;
  }

  .category-header {
    margin-bottom: 24px;
    padding-bottom: 6px;
  }

  .profile-indicator__chart_description {
    max-width: none;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .profile-indicator {
    padding-bottom: 20px;
  }

  .profile-indicator__chart_source {
    padding-left: 0px;
  }

  .bar-chart__labels {
    padding-left: 0px;
  }

  .filter__dropdown_wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .bar-chart__underline {
    margin-left: 0px;
  }

  .profile-indicator__filters {
    margin-bottom: 0px;
  }

  .bar-chart__rows {
    padding-bottom: 0px;
  }

  .profile-indicator__chart_body {
    padding-right: 0px;
  }

  .bar-chart__x-label {
    margin-top: 12px;
    padding-left: 0px;
  }

  .bar-chart__row_tooltip {
    display: none;
  }

  .bar-chart__row {
    height: 36px;
    margin-bottom: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .bar-chart__row_label {
    width: 100%;
    max-width: none;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .bar-chart__label_value {
    display: none;
  }

  .nav__search_button {
    width: 44px;
  }

  .nav__content_title {
    width: 100%;
    min-width: 100px;
  }

  .nav__menu-trigger {
    margin-left: 6px;
  }

  .nav__link.tutorial__open {
    height: 37px;
    padding-right: 6px;
  }

  .mapping-options__filter {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .tutorial {
    overflow: auto;
  }

  .nav__link_text {
    display: none;
  }

  .page-header {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .content-block {
    padding-right: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
  }

  .content-block.bg-green.first {
    margin-bottom: 32px;
    padding-top: 32px;
  }

  .location__facilities_header {
    width: 100%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .mag-geo__dropdown_wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .rich-data__print {
    top: 70px;
    display: none;
  }

  .div-block {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .location__facilities_download-all {
    margin-bottom: 8px;
  }

  .location__facilities_header-wrapper {
    margin-bottom: 8px;
  }

  .point-mapper-options__filter {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .profile-indicator__filter {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .point-filters__filter {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .tutorial-2 {
    overflow: auto;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 2em;
  }

  .rich-data-content {
    padding-top: 24px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .section {
    margin-bottom: 48px;
  }

  .body {
    font-size: 13px;
  }

  .nav {
    padding-right: 10px;
    padding-left: 10px;
  }

  .nav.narrow {
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-link {
    margin-top: -124px;
  }

  .panel-toggle {
    width: 44px;
    height: 44px;
  }

  .svg-icon {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .breadcrumb {
    font-size: 0.7em;
  }

  .location-facility {
    width: 100%;
  }

  .location__title {
    margin-bottom: 16px;
  }

  .rich-data-nav__toggle {
    left: 12px;
    top: auto;
    right: auto;
    bottom: 48px;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #333;
  }

  .location__facilities_sources {
    margin-left: -8px;
  }

  .sticky-header {
    top: 52px;
  }

  .sub-category-header__key-metrics_title {
    font-size: 0.8em;
  }

  .key-metric {
    width: 100%;
  }

  .dropdown-menu {
    z-index: 1;
  }

  .sticky-header__current-location {
    height: 32px;
    margin-left: 0px;
    padding-right: 12px;
    padding-left: 12px;
  }

  .nav__content.narrow {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .nav__content_search {
    display: none;
  }

  .point-mapper {
    display: none;
  }

  .data-mapper {
    display: none;
  }

  .mapping-options__filter_menu {
    z-index: 1;
  }

  .login {
    width: 90%;
    padding: 12px 16px;
  }

  .login__close {
    margin-right: -8px;
  }

  .login-form__actions {
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .tutorial {
    width: 90%;
    max-height: 90vh;
    padding: 10px 16px 16px;
    grid-row-gap: 4px;
  }

  .tutorial__close {
    margin-right: -6px;
  }

  .tutorial__slider {
    margin-right: -16px;
    margin-left: -16px;
  }

  .tutorial__slide-content {
    overflow: auto;
  }

  .tutorial__slide-info {
    left: 16px;
    right: 16px;
    margin-left: -32px;
    padding: 10px 12px;
  }

  .tutorial__slide_button.next {
    margin-right: 16px;
    margin-left: 8px;
  }

  .tutorial__slide_button.previous {
    margin-right: 8px;
    margin-left: 16px;
  }

  .location__tutorial {
    width: 24px;
    height: 24px;
  }

  .location__tutorial_text {
    display: none;
  }

  .page-header {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .content-block {
    padding-right: 20px;
    padding-left: 20px;
  }

  .content-block.bg-green.first {
    margin-bottom: 16px;
  }

  .page-header__overline {
    font-size: 0.85em;
  }

  .page-header__title {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .page-header__website-link {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .warning {
    width: 90%;
    padding: 4px 16px;
    grid-row-gap: 4px;
  }

  .profile-indicator__filter_menu {
    z-index: 1;
  }

  .point-filters__filter-menu {
    z-index: 1;
  }

  .modal {
    width: 90%;
    padding: 4px 16px;
    grid-row-gap: 4px;
  }

  .data-mapper--v2 {
    display: none;
  }

  .tutorial__slide-info-2 {
    left: 16px;
    right: 16px;
    margin-left: -32px;
    padding: 10px 12px;
  }

  .tutorial-2 {
    width: 90%;
    max-height: 90vh;
    padding: 10px 16px 16px;
    grid-row-gap: 4px;
  }

  .tutorial__slide_button-2.next {
    margin-right: 16px;
    margin-left: 8px;
  }

  .tutorial__slide_button-2.previous {
    margin-right: 8px;
    margin-left: 16px;
  }
}

#w-node-_8370c3c3-dd27-5ba4-e185-11a67e6ccd44-2ab2da33 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_138f8096-55b2-2cf7-756a-31f6ef2aede4-2ab2da33 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_2a3c695e-4c47-ff4f-0294-09c3cf7a9fe2-2ab2da33 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}

#w-node-_5c3e6bec-baae-1fe9-3f2a-222d0aaebade-2ab2da33 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}

#w-node-_80c1d4e4-3db7-9834-435b-fefcf113a3cc-2ab2da33 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
}

#w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-2ab2da33 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
}

#w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-2ab2da33 {
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-e1033b14-ea6f-0f0d-1fc3-909d66137000-2ab2da33 {
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5c-2ab2da33 {
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5d-2ab2da33 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa61-2ab2da33 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c855a-2ab2da33 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c8568-2ab2da33 {
  -ms-grid-column-span: 2;
  grid-column-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c85a0-2ab2da33 {
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_91faa97b-05e0-ee56-35d9-a4312e607bde-2ab2da33 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_91faa97b-05e0-ee56-35d9-a4312e607be7-2ab2da33 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0cc195d0-8237-0a5c-88c5-6bd36b98a8c8-2ab2da33 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_8d9eb87a-805a-ef6a-0ef3-bca9e2f0f20f-2ab2da33 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-f25982b9-693e-74dc-9a2f-7590f031f5c0-00b2da65 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-f25982b9-693e-74dc-9a2f-7590f031f5c3-00b2da65 {
  -ms-grid-column-span: 2;
  grid-column-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f25982b9-693e-74dc-9a2f-7590f031f5e4-00b2da65 {
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_4d72d44a-a353-deb9-cdd6-3e7554d9fdf7-00b2da65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 7;
  grid-column-start: span 7;
  -ms-grid-column-span: 7;
  grid-column-end: span 7;
}

#w-node-_90685573-4695-014e-a5e9-bcacce32cf46-00b2da65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-c5c6b4ad-353e-3fe1-6487-d2e40839bd81-00b2da65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-_5e0b3564-86ec-d83a-3bc6-4f0e27157bd9-00b2da65 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-a1fbc524-d46c-78fc-46af-4011e6b49689-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-de90d542-cc0b-b32b-2c3c-57eeb685ffd2-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5f4fd3a3-1c16-aa44-33f5-905265a58174-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_15e74dcc-4ebc-9e20-0dfb-8e62eff40f7c-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_673b600c-07de-f165-508c-adc7cca0f0c7-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#indicator-version.w-node-_906a3f22-7504-cf26-ae0b-cef043e37405-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#indicator-version.w-node-e48665a8-2ee8-c02c-3dd4-bf46bd52b2c8-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_1c81a672-7c56-c120-094b-ca488c73ef76-68b2da6a {
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
}

#w-node-_1c81a672-7c56-c120-094b-ca488c73ef77-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1c81a672-7c56-c120-094b-ca488c73ef7b-68b2da6a {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_8370c3c3-dd27-5ba4-e185-11a67e6ccd44-6d818144 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_138f8096-55b2-2cf7-756a-31f6ef2aede4-6d818144 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_2a3c695e-4c47-ff4f-0294-09c3cf7a9fe2-6d818144 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}

#w-node-_5c3e6bec-baae-1fe9-3f2a-222d0aaebade-6d818144 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}

#w-node-_80c1d4e4-3db7-9834-435b-fefcf113a3cc-6d818144 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
}

#w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-6d818144 {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
}

#w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-6d818144 {
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-e1033b14-ea6f-0f0d-1fc3-909d66137000-6d818144 {
  -ms-grid-column-span: 4;
  grid-column-end: 5;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5c-6d818144 {
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5d-6d818144 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa61-6d818144 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c855a-6d818144 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c8568-6d818144 {
  -ms-grid-column-span: 2;
  grid-column-end: 4;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c85a0-6d818144 {
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_91faa97b-05e0-ee56-35d9-a4312e607bde-6d818144 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_91faa97b-05e0-ee56-35d9-a4312e607be7-6d818144 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0cc195d0-8237-0a5c-88c5-6bd36b98a8c8-6d818144 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_8d9eb87a-805a-ef6a-0ef3-bca9e2f0f20f-6d818144 {
  -ms-grid-column-align: end;
  justify-self: end;
}

@media screen and (max-width: 991px) {
  #w-node-_4d72d44a-a353-deb9-cdd6-3e7554d9fdf7-00b2da65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_2331a044-806f-907c-13ce-926b3e9c8568-2ab2da33 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 2;
    grid-column-end: 4;
  }

  #w-node-f25982b9-693e-74dc-9a2f-7590f031f5c3-00b2da65 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 2;
    grid-column-end: 4;
  }

  #w-node-_2331a044-806f-907c-13ce-926b3e9c8568-6d818144 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 2;
    grid-column-end: 4;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-2ab2da33 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-2ab2da33 {
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }

  #w-node-f25982b9-693e-74dc-9a2f-7590f031f5e4-00b2da65 {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
  }

  #w-node-_4d72d44a-a353-deb9-cdd6-3e7554d9fdf7-00b2da65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_90685573-4695-014e-a5e9-bcacce32cf46-00b2da65 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row-align: end;
    align-self: end;
  }

  #w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-6d818144 {
    -ms-grid-column-span: 3;
    grid-column-end: 3;
  }

  #w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-6d818144 {
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-column: 1;
    grid-column-start: 1;
  }
}

